import React from 'react'
import data from '../config'


export default function PrivacyPolicy() {
    return (
        <div className='bg-app py-5'>
            <div className="container">
                <h4 className='mb-4'>Privacy Policy</h4>
                <p><strong>Effective Date:</strong> [July 2020]</p>
      <p><strong>Last Updated:</strong> [Dec 2024]</p>
      <p>
        At <strong>{data.fullname}</strong> we protecting your privacy is our priority.
        This Privacy Policy outlines how we collect, use, and safeguard your personal
        information when you visit our website {data.website} or engage with our services. By using our Website, you consent to the practices
        described in this policy.
      </p>

      <h4>1. Information We Collect</h4>
      <p>
        We collect personal and non-personal information to improve our services and
        provide a better user experience.
      </p>
      <h5>1.1 Information You Provide to Us</h5>
      <p>We collect information you provide directly, such as:</p>
      <ul>
        <li><strong>Contact Information:</strong> Name, email address, phone number, job title, company name.</li>
        <li>
          <strong>Form Submissions:</strong> Information submitted through lead generation forms, surveys, or other
          Website features.
        </li>
      </ul>
      <h5>1.2 Information Collected Automatically</h5>
      <p>When you interact with our Website, we may collect:</p>
      <ul>
        <li><strong>Technical Data:</strong> IP address, browser type, operating system, device type.</li>
        <li><strong>Usage Data:</strong> Pages visited, time spent, navigation paths, referring URLs.</li>
        <li><strong>Location Data:</strong> Approximate geolocation derived from your IP address.</li>
      </ul>
      <h5>1.3 Cookies and Tracking Technologies</h5>
      <p>We use cookies, web beacons, and similar technologies to:</p>
      <ul>
        <li>Enhance your browsing experience.</li>
        <li>Understand user behavior and preferences.</li>
        <li>Deliver personalized content and advertisements.</li>
      </ul>
      <p>
        For more details, refer to our <a href="/cookie-policy">Cookie Policy</a>.
      </p>

      <h4>2. How We Use Your Information</h4>
      <p>We use your information to:</p>
      <ul>
        <li><strong>Provide Services:</strong> Deliver relevant content, resources, and services requested by you.</li>
        <li><strong>Improve Website Performance:</strong> Analyze Website usage and optimize features.</li>
        <li>
          <strong>Communicate:</strong> Send newsletters, updates, and marketing materials (with your consent).
        </li>
        <li>
          <strong>Generate Leads:</strong> Share your information with trusted partners to deliver requested products
          or services.
        </li>
        <li><strong>Ensure Compliance:</strong> Meet legal and regulatory requirements.</li>
      </ul>

      <h4>3. Sharing Your Information</h4>
      <p>
        We respect your privacy and do not sell your personal information. However, we may
        share your data in the following cases:
      </p>
      <h5>3.1 Service Providers</h5>
      <p>
        We partner with trusted third-party vendors to assist with operations, such as
        hosting, email marketing, analytics, or customer support.
      </p>
      <h5>3.2 Business Partners</h5>
      <p>
        If you request services related to our partners, we may share your details with them
        to fulfill your request.
      </p>
      <h5>3.3 Legal Requirements</h5>
      <p>
        We may disclose your information if required by law, regulation, or to protect the
        rights, safety, and property of our users or others.
      </p>

      <h4>4. Your Rights and Choices</h4>
      <p>We respect your rights and provide options to manage your data.</p>
      <h5>4.1 Access and Correction</h5>
      <p>You may request access to your personal data or request corrections to inaccuracies.</p>
      <h5>4.2 Data Deletion</h5>
      <p>You can request the deletion of your personal data, subject to applicable legal obligations.</p>
      <h5>4.3 Marketing Preferences</h5>
      <p>
        You can unsubscribe from marketing communications by clicking the "unsubscribe" link in
        our emails or contacting us.
      </p>
      <h5>4.4 Cookies and Tracking</h5>
      <p>
        Adjust your browser settings to manage cookie preferences. Some features may be
        unavailable without cookies.
      </p>

      <h4>5. Data Security</h4>
      <p>
        We implement robust security measures to protect your personal data from unauthorized
        access, alteration, or disclosure. While we strive for the highest level of security,
        no system can be completely secure.
      </p>

      <h4>6. Data Retention</h4>
      <p>
        We retain your data only as long as necessary to fulfill the purposes outlined in
        this Privacy Policy or as required by law.
      </p>

      <h4>7. Third-Party Links</h4>
      <p>
        Our Website may contain links to external websites or services. We are not
        responsible for their content or privacy practices. Please review their privacy
        policies independently.
      </p>

      <h4>8. International Users</h4>
      <p>
        If you are accessing our Website from outside [Your Country], be aware that your data
        may be transferred, stored, or processed in a different jurisdiction. By using our
        Website, you consent to such transfers.
      </p>

      <h4>9. Children’s Privacy</h4>
      <p>
        Our Website is not designed for individuals under 18. We do not knowingly collect
        personal data from minors. If we become aware of such data, we will delete it
        promptly.
      </p>

      <h4>10. Updates to This Privacy Policy</h4>
      <p>
        We may revise this Privacy Policy from time to time to reflect changes in our
        practices or comply with legal requirements. Updates will be posted on this page
        with a revised effective date.
      </p>

      <h4>11. Contact Us</h4>
      <p>If you have questions, concerns, or requests regarding this Privacy Policy, please contact us at:</p>
      <p>
        <strong>{data.fullname}</strong>
        <br />
        <strong>Address:</strong> Head Office (IN): Viman Nagar, Pune (MH) – 411014
        <br />
        <strong>Email:</strong>{" "}
        {data.email}
      </p>
            </div>
        </div>
    )
}
